import { admin, admin2 } from '@/api/instance'

// -- 儲值金

const walletRecord = (shopId) => `/${shopId}/walletRecord`

// -- 取得儲值金列表
export const GetWalletRecord = async ({
  shopId,
  start,
  limit,
  phone,
  noteCodeType,
  creatorName,
  createdAtStart,
  createdAtEnd,
}) => {
  const res = await admin({
    method: 'get',
    url: `${walletRecord(shopId)}`,
    params: {
      start,
      limit,
      phone,
      noteCodeType,
      creatorName,
      createdAtStart,
      createdAtEnd,
    },
  })

  return res.data
}

// -- 取得儲值金列表數量
export const GetWalletRecordCount = async ({
  shopId,
  phone,
  noteCodeType,
  creatorName,
  createdAtStart,
  createdAtEnd,
}) => {
  const res = await admin({
    method: 'get',
    url: `${walletRecord(shopId)}/count`,
    params: {
      phone,
      noteCodeType,
      creatorName,
      createdAtStart,
      createdAtEnd,
    },
  })

  return res.data
}

// -- 取得儲值金查詢指標資料
export const GetWalletRecordIndicators = async ({
  shopId,
  createdAtStart,
  createdAtEnd,
}) => {
  const res = await admin({
    method: 'get',
    url: `${walletRecord(shopId)}/findIndicators`,
    params: {
      createdAtStart,
      createdAtEnd,
    },
  })

  return res.data
}

//  => 儲值金門市紀錄
export const FindIndicatorsByBranch = async ({
  shopId,
  createdAtStart,
  createdAtEnd,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/walletRecord/findIndicatorsByBranch`,
    params: {
      createdAtStart,
      createdAtEnd,
    },
  })
}
