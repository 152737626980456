<template>
  <el-button-group class="group-time-filter-button">
    <el-button
      class="date-range-btn"
      :class="{ active: timeRangeType === 'today' && !customRangeSync }"
      @click="changeTimeRangeType('today')"
    >
      本日
    </el-button>
    <el-button
      class="date-range-btn"
      :class="{ active: timeRangeType === 'week' && !customRangeSync }"
      @click="changeTimeRangeType('week')"
    >
      本週
    </el-button>
    <el-button
      class="date-range-btn"
      :class="{ active: timeRangeType === 'month' && !customRangeSync }"
      @click="changeTimeRangeType('month')"
    >
      本月
    </el-button>
    <el-button
      class="date-range-btn"
      :class="{ active: customRangeSync }"
      @click="$emit('custom')"
    >
      自定義
    </el-button>
  </el-button-group>
</template>
<script>
export default {
  name: 'GroupTimeFilterButton',
  props: {
    isCustomRange: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    timeRangeType: 'today',
  }),
  computed: {
    customRangeSync: {
      get () {
        return this.isCustomRange
      },
      set (value) {
        this.$emit('update:isCustomRange', value)
      },
    },
  },
  methods: {
    async changeTimeRangeType (type) {
      this.timeRangeType = type
      this.$emit(type)
      this.customRangeSync = false
      this.$emit('change')
    },
  },
}
</script>

<style lang="postcss" scoped>
.group-time-filter-button {
  @apply max-w-[566px];
}
.active {
  @apply border border-primary-100 bg-primary-100 text-white;
}

::v-deep .el-button {
  @apply shadow-none;
}

::v-deep .el-button:hover {
  @apply bg-primary-100 border-primary-100 text-white;
}

.date-range-btn {
  @apply px-[53.75px];
}
</style>
